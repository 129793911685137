import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "react-i18next";
import {Link} from "gatsby-plugin-react-i18next";

const LocksSupport = () => {
  return (
      <>
        <section id={'SmartLocks'} className="smart-locks">
          <div className="container">
            <h2 className="sub support support-pad">
              <Trans>
                smart_locks
              </Trans>
              <span><Trans>sl_headline_list_1</Trans></span>
            </h2>

            <div className="smart-lock-wrap">
              <Link to={'/support/smart-locks/curve/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-curve-smart-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Curve Smart Lock"
                  />
                  <p>
                    <span>Curve<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/smart-locks/aspire/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-net-aspire-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Aspire Smart Lock"
                  />
                  <p>
                    <span>Aspire<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/smart-locks/versa/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-net-versa-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Versa Smart Lock"
                  />
                  <p>
                    <span>Versa<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/smart-locks/orbit/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-net-orbit-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Orbit Smart Lock"
                  />
                  <p>
                    <span>Orbit<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/smart-locks/pivot/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/pivot-system.png'}
                      loading={'lazy'}
                      width={201}
                      height={145}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Pivot"
                  />
                  <p>
                    <span>Pivot<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
            </div>
            <br/><br/>
            {/*<div className="smart-digilink-container">*/}
            {/*  <div className="thumbnail">*/}
            {/*    <StaticImage*/}
            {/*        src={'../../images/locks/DL22-digilink-600x497.png'}*/}
            {/*        loading={'lazy'}*/}
            {/*        width={200}*/}
            {/*        height={148}*/}
            {/*        quality={100}*/}
            {/*        formats={["auto", "webp", "avif"]}*/}
            {/*        alt="DigiLink Smart Lock"*/}
            {/*    />*/}
            {/*    <p>*/}
            {/*      <span>DigiLink<sup>&reg;</sup></span>*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </section>
        <div className="section-delimeter container"/>
        <section id={'ElectronicLocks'} className="electronic-locks">
          <div className="container">
            <h2 className="sub">
              <Trans>
                electronic_locks
              </Trans>
                <span><Trans>el_headline_list_1</Trans></span>
            </h2>
            <div className="row">
              <Link to={'/support/electronic-locks/curve/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-curve-ba-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Curve Electronic Lock"
                  />
                  <p>
                    <span>Curve<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/aspire/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-aspire-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Aspire Electronic Lock"
                  />
                  <p>
                    <span>Aspire<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/versa/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-versa-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Versa Electronic Lock"
                  />
                  <p>
                    <span>Versa<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/orbit/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-orbit-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Orbit Electronic Lock"
                  />
                  <p>
                    <span>Orbit<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <section className="legacy-locks">
          <div className="container legacy">
            <h2 className="sub legacy">
              <Trans>
                legacy_locks
              </Trans>
            </h2>
            <div className="row legacy">

              <Link to={'/support/electronic-locks/numeris-versa/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-numeris-versa-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Numeris Versa Lock"
                  />
                  <p>
                    <span>Numeris Versa<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/numeris-aspire/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-numeris-aspire-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Numeris Aspire Lock"
                  />
                  <p>
                    <span>Numeris Aspire<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/axis/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-axis-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Axis Electronic Lock"
                  />
                  <p>
                    <span>NextLock Axis<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/cue/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-cue-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Cue Electronic Lock"
                  />
                  <p>
                    <span>NextLock Cue<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
            </div>
            <div className={'row legacy'}>
              <Link to={'/support/electronic-locks/sola/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-sola-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Sola Electronic Lock"
                  />
                  <p>
                    <span>NextLock Sola<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/range/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-range-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Range Electronic Lock"
                  />
                  <p>
                    <span>NextLock Range<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/a-series-standard/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-4Gstand-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Standard"
                  />
                  <p>A Series Standard</p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/a-series-vertical/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-4Gvertical-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Vertical"
                  />
                  <p>A Series Vertical</p>
                </div>
              </Link>
            </div>
            <div className={'row legacy'}>
              <Link to={'/support/electronic-locks/classic-cam/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-classiccam-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Vertical"
                  />
                  <p>Classic Cam</p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/3rd-generation/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../pages/support/electronic-locks/3rd-generation/images/third-generation-small.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="3rd Generation"
                  />
                  <p>3rd Generation</p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/t-series/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-tseries-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="T-series"
                  />
                  <p>T-series</p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/kte-kpe/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-ktekpe-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="KTE/KPE"
                  />
                  <p>KTE/KPE</p>
                </div>
              </Link>
            </div>
            <div className={'row legacy'}>
              <Link to={'/support/electronic-locks/2nd-generation/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-2G-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="2nd Generation"
                  />
                  <p>2nd Generation</p>
                </div>
              </Link>
              <Link to={'/support/electronic-locks/1st-generation/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-1G-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={150}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="1st Generation"
                  />
                  <p>1st Generation</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <div className="section-delimeter container"/>
        <section className="electronic-locks">
          <div className="container mech">
            <h2 className="sub">
              <Trans>
                mechanical_lock
              </Trans>
            </h2>
            <div className="row mech">
              <Link to={'/support/mechanical-locks/mech/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/DL22-mech-600x497.png'}
                      loading={'lazy'}
                      width={200}
                      height={148}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="NEED ALT TAG"
                  />
                  <p>
                    <span>Mech<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </>
  )
};
export default LocksSupport;
