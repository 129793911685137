module.exports = {
  AFRICA: "Africa",
  ANTARCTICA: "Antarctica",
  ASIA: "Asia",
  EUROPE: "Europe",
  OCEANIA: "Oceania",
  NORTH_AMERICA: "North America",
  SOUTH_AMERICA: "South America",
  SESSION_CONTINENT: "geo-continent",
  SESSION_COUNTRY: "geo-country",
  SESSION_COUNTRY_CODE: "geo-country-code",
  SESSION_STATE: "geo-state",
  SESSION_STATE_CODE: "geo-state-code",
  SESSION_CITY: "geo-town"
};
